<template>
  <div class="messageManagement">
    <div>
      <el-input placeholder="请输入关键词"
                v-model="queryNews"
                class="input-box"></el-input>
      <el-button type="primary"
                 @click="loadMessage">查 询</el-button>
    </div>
    <el-table :data="messageList">
      <el-table-column label="标题"
                       align="center"
                       prop="title">
      </el-table-column>
      <el-table-column label="状态"
                       width="120px"
                       align="center">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status===1?'success':''">{{scope.row.statusStr}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="发送人"
                       width="120px"
                       align="center"
                       prop="opername"></el-table-column>
      <el-table-column label="发送时间"
                       align="center"
                       prop="opertime"></el-table-column>
      <el-table-column label="操作"
                       align="center"
                       width="200px">
        <template slot-scope="scope">
          <el-button type="primary"
                     @click="viewMessage(scope.row)">查 看</el-button>
          <el-button type="danger"
                     @click="deleteMessage(scope.$index,scope.row.id)">删 除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background
                   layout="prev,pager,next"
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="loadMessage"></el-pagination>
  </div>
</template>

<script>
export default {
  name: "messageManagement",
  data() {
    return {
      messageList: [],
      queryNews: "",
      page: { currentPage: 0, pageCount: 1 },
    };
  },
  created() {
    this.loadMessage(1);
  },
  methods: {
    loadMessage(page) {
      const that = this;
      let data = { indexNo: page, status: "", new_type: 1 }; //new_type固定查看web端消息
      if (this.queryNews) {
        data.name = this.queryNews;
      }
      this.$post("/base/queryNews.do", data)
        .then((res) => {
          that.page.currentPage = page;
          that.page.pageCount = res.data.pageCount;
          that.messageList = res.data.rows;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    // type： 1   path：oneTicketVeto                    一票否决
    // type： 2   path：useCar                               用车
    // type：3   path：reportForRepair                  报修
    // type： 4   path：leave                                  教师请假
    // type：5   path：studentLeaveAudit             学生请假
    // type： 6   path：bookingManagement       预约访校
    // type：7   path：teachingPatro                     教学巡查
    // type： 8  path：temporaryLeave                 临时假
    // type：9   path：sendingManagement         发文管理
    // type： 10   path：receivingManagement    收文管理
    // type： 11   path：scoringSystem                评分系统

    viewMessage(row) {
      const that = this;
      this.$post("/base/updateNewsStatus.do", { id: row.id })
        .then((res) => {
          that.loadUnreadMessage();
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
      this.$router.push({ name: row.path });
    },
    loadUnreadMessage() {
      const that = this;
      this.$post("/base/queryNotNews.do", {})
        .then((res) => {
          // that.unreadMessage = res.data.data.length;
          that.$store.commit("updateUnreadMessage", res.data.data.length);
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    deleteMessage(index, id) {
      const that = this;
      this.$confirm("是否删除该信息", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/deleteNews.do", { id })
            .then((res) => {
              that.messageList.splice(index, 1);
              that.$message({
                type: "success",
                message: res.data,
              });
            })
            .catch((err) => {
              that.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消更改",
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.input-box {
  width: 200px;
  margin-right: 5px;
}
</style>
